<template>
  <ion-page>
    <ion-content fullscreen>
      <ion-card color="primary">
        <ion-card-content v-if="qrcodeInfo.inspection_data">
          <p>{{$t('labelChipset')}}: 
            <ion-text v-if="qrcodeInfo.inspection_data.chipset && qrcodeInfo.inspection_data.chipset.mainboard">
              {{ qrcodeInfo.inspection_data.chipset.mainboard }}
            </ion-text>
          </p>
          
          <!-- <p>{{$t('labelCpu')}}: 
            <template v-if="qrcodeInfo.inspection_data.cpu">
              <ion-text v-for="(item, index) in qrcodeInfo.inspection_data.cpu" :key="index">{{ item.specification }}</ion-text>
            </template>
          </p> -->

          <!-- <p>{{$t('labelAdapter')}}:
            <template v-if="qrcodeInfo.inspection_data.display">
              <ion-text v-for="(item, index) in qrcodeInfo.inspection_data.display" :key="index">{{ item.adapter }}</ion-text>
            </template>
          </p> -->

          <p v-if="noteCategoryCpu.cate_name">{{$t('labelCpu')}}:
            <ion-text>{{ noteCategoryCpu.cate_name }}</ion-text>
          </p>
          
          <p v-if="noteCategoryBrand.cate_name">{{$t('labelBrand')}}:
            <ion-text>{{ noteCategoryBrand.cate_name }}</ion-text>
          </p>
          
          <p v-if="noteCategoryMemory.cate_name">{{$t('labelMemory')}}:
            <ion-text>{{ noteCategoryMemory.cate_name }}</ion-text>
          </p>
          
          <p v-if="noteCategoryLcdSizeNum.cate_name">{{$t('labelLCD')}}:
            <ion-text>{{ noteCategoryLcdSizeNum.cate_name }}</ion-text>
          </p>
          
          <p v-if="noteCategoryBonus1Num.cate_name">{{$t('labelStorage')}}:
            <ion-text>{{ noteCategoryBonus1Num.cate_name }}</ion-text>
          </p>
          
          <p v-if="noteCategoryDeduct1Num.cate_name">{{$t('labelAdapter')}}:
            <ion-text>{{ noteCategoryDeduct1Num.cate_name }}</ion-text>
          </p>
          
          <p v-if="noteCategoryDeduct2Num.cate_name">{{$t('labelBattery')}}:
            <ion-text>{{ noteCategoryDeduct2Num.cate_name }}</ion-text>
          </p>
          
          <p v-if="noteCategoryDeduct3Num.cate_name">{{$t('labelKeyboard')}}:
            <ion-text>{{ noteCategoryDeduct3Num.cate_name }}</ion-text>
          </p>
         
          <!-- <p>{{$t('labelDmi')}}:</p>
          <ion-text v-if="qrcodeInfo.inspection_data.dmi">
            <p v-if="qrcodeInfo.inspection_data.dmi.model">{{$t('labelModel')}}: {{ qrcodeInfo.inspection_data.dmi.model }}</p>
            <p v-if="qrcodeInfo.inspection_data.dmi.product">{{$t('labelProduct')}}: {{ qrcodeInfo.inspection_data.dmi.product }}</p>
            <p v-if="qrcodeInfo.inspection_data.dmi.serial">{{$t('labelSerial')}}: {{ qrcodeInfo.inspection_data.dmi.serial }}</p>
          </ion-text> -->
          
          <!-- <p>{{$t('labelMemory')}}:</p>
          <ion-text v-if="qrcodeInfo.inspection_data.chipset.memorysize">
            <p>{{ qrcodeInfo.inspection_data.chipset.memorysize }}</p>
          </ion-text> -->
          
          <!-- <p>{{$t('labelMonitor')}}:</p>
          <ion-text v-if="qrcodeInfo.inspection_data.monitor">
            <p v-for="(item, index) in qrcodeInfo.inspection_data.monitor" :key="index">
              {{$t('labelManufacturingdate')}}: {{ item.manufacturingdate }}<br/>
              {{$t('labelModel')}}: {{ item.model }}<br/>
              {{$t('labelSerial')}}: {{ item.serial }}<br/>
              {{$t('labelSize')}}: {{ item.size }}<br/>
            </p>
          </ion-text> -->
          
          <!-- <p>{{$t('labelStorage')}}:</p>
          <ion-text v-if="qrcodeInfo.inspection_data.storage">
            <p v-for="(item, index) in qrcodeInfo.inspection_data.storage" :key="index">
              {{$t('labelManufacturingdate')}}: {{ item.capacity }}<br/>
              {{$t('labelModel')}}: {{ item.type }}<br/>
            </p>
          </ion-text> -->

          <ion-text>
            <h1 v-if="orderInfo.grade">{{$t('labelGrade')}} : {{ noteCategoryGradeNum.cate_name }} : {{ orderInfo.grade }}</h1>
            <h2 v-if="orderInfo.grade_memo">{{$t('labelGradeMemo')}} : {{ orderInfo.grade_memo }}</h2>
          </ion-text>
          
          <ion-text>
            <!-- <h1 v-if="orderInfo.special">{{$t('labelSpecial')}} : {{ orderInfo.special }}</h1>
            <h2 v-if="orderInfo.special_memo">{{$t('labelSpecialMemo')}} : {{ orderInfo.special_memo }}</h2> -->
            <h1 v-if="noteSpecialNum.special_info_name">{{$t('labelSpecialName')}} : {{ noteSpecialNum.special_info_name }}</h1>
          </ion-text>

          <ion-text>
            <h1>{{$t('labelTotalPrice')}} : {{ total_price }}</h1>
          </ion-text>
        </ion-card-content>
      </ion-card>

      <template v-if="orderInfo.is_confirm === 'F' || orderInfo.is_confirm === 'C'">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12">
              <ion-item>
                <ion-label>{{$t('labelEmployId')}} : {{ orderInfo.savina_shop_code }}</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{$t('labelOrderName')}} : {{ orderInfo.order_name }}</ion-label>
              </ion-item>
              <!-- <ion-item>
                <ion-label>{{$t('labelUserPassword')}} : {{ orderInfo.user_password }}</ion-label>
              </ion-item> -->
              <ion-item>
                <ion-label>{{$t('labelSerialNo')}} : {{ orderInfo.sn }}</ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12">
              <ion-img
                :src="orderInfo.order_picture"
                @ionError="order_picture = '/assets/images/common/no-image.png'"
              />
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid>
          <ion-row>
            <ion-col><ion-button color="medium" size="" expand="full" @click="handleBack">{{$t('buttonLabelBack')}}</ion-button></ion-col>
          </ion-row>
        </ion-grid>
      </template>
      <template v-else>
        <ion-item>
          <ion-label position="floating">{{$t('labelEmployId')}}</ion-label>
          <ion-input :value="savina_shop_code" :placeholder="$t('labelEmployId')" @ionInput="handleInput('savina_shop_code', $event)"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">{{$t('labelOrderName')}}</ion-label>
          <ion-input :value="order_name" :placeholder="$t('labelOrderName')" @ionInput="handleInput('order_name', $event)"></ion-input>
        </ion-item>

        <!-- <ion-item>
          <ion-label position="floating">{{$t('labelUserPassword')}}</ion-label>
          <ion-input :value="user_password" :placeholder="$t('labelUserPassword')" @ionInput="handleInput('user_password', $event)"></ion-input>
        </ion-item> -->

        <ion-item>
          <ion-label position="floating">{{$t('labelSerialNo')}}</ion-label>
          <ion-input :value="sn" :placeholder="$t('labelSerialNo')" @ionInput="handleInput('sn', $event)"></ion-input>
          <ion-button color="secondary" size="small" @click="handlemBarcodeModal">QR SCAN</ion-button>
        </ion-item>

        <ion-grid>
          <ion-row class="l-photo">
            <ion-col size="12">
              <ion-img
                v-if="order_picture"
                :src="order_picture"
                @ionError="order_picture = '/assets/images/common/no-image.png'"
                @click="handleOrderPicture()"
              />
              <ion-button v-if="!order_picture" @click="handleOrderPicture()">
                <span>{{$t('labelOrderPicture')}}</span>
                <ion-icon :icon="cameraOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="order_picture" class="clear" @click="removeOrderPicture()">
                <ion-icon :icon="trash"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid>
          <ion-row>
            <ion-col size="12" size-md="6"><ion-button color="medium" expand="full" @click="handleCanceledSale">{{$t('buttonLabelCanceledSale')}}</ion-button></ion-col>
            <ion-col size="12" size-md="6"><ion-button color="primary" expand="full" @click="handleApplySale">{{$t('buttonLabelApplySale')}}</ion-button></ion-col>
          </ion-row>
        </ion-grid>
      </template>

      <ion-modal id="sn" :is-open="isOpenBarcodeModal" @willDismiss="onWillDismiss">
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonModal,
  IonPage,
  IonContent, 
  IonButton, 
  IonCard, 
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  toastController,
  loadingController,
  // useIonRouter
} from '@ionic/vue'
import { StreamBarcodeReader } from "vue-barcode-reader";
import { cameraOutline, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { onMounted, ref } from 'vue'
import { useRoute/* , useRouter */ } from 'vue-router'
import { useI18n } from 'vue-i18n';
import { getData, addFileData, listData, updateData } from '@/api/board/common'
import { DataURIToBlob } from '@/utils/commonUtils'

export default {
  name: 'apply-detail',
  components: {
    IonModal,
    IonPage,
    IonContent,
    IonButton,
    IonCard, 
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    StreamBarcodeReader
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    const route = useRoute()
    // const router = useIonRouter()
    const total_price = ref(0)
    const orderInfo = ref({})
    const qrcodeInfo = ref({})
    let orderNum = ref(undefined)
    let order_picture = ref(undefined)
    let savina_shop_code = ref(undefined)
    let order_name = ref(undefined)
    let user_password = ref(undefined)
    let sn = ref(undefined)

    let noteCategoryBrand = ref({})
    let noteCategoryCpu = ref({})
    let noteCategoryMemory = ref({})
    let noteCategoryLcdSizeNum = ref({})
    let noteCategoryBonus1Num = ref({})
    let noteCategoryDeduct1Num = ref({})
    let noteCategoryDeduct2Num = ref({})
    let noteCategoryDeduct3Num = ref({})
    let noteCategoryGradeNum = ref({})
    let noteSpecialNum = ref({})

    let isOpenBarcodeModal = ref(false)
    
    onMounted(async () => {
      await getOrderNum()
      await getDetailData()
    })

    const updateNoteOrderIsConfirm = async (is_confirm) => {
      const apiUrl = `/api/notebook/order/`
      const payload = {
        num: Number(route.params.num),
        is_confirm: is_confirm
      }
      await updateData(apiUrl, payload).then(() => {
        if (is_confirm === 'F') {
          location.href = '/checkup/apply'
          // router.push({ path: '/checkup/result' })
          /* router.push({
            name: 'checkup',
            params: {
              isState: 'result'
            }
          }) */
        } else if (is_confirm === 'C') {
          location.href = '/checkup/apply'
          // router.push({ path: '/checkup/apply' })
          /* router.push({
            name: 'checkup',
            params: {
              isState: 'apply'
            }
          }) */
        }
      })
    }

    const getDetailData = async () => {
      let apiUrl = `/api/notebook/order/orderdetail/${Number(route.params.num)}`
      await listData(apiUrl).then(response => {
        orderInfo.value = response
        qrcodeInfo.value = JSON.parse(response.json_data) 
        total_price.value = response.total_price

        if (response.basic_num) {
          getCategoryDetail(response.basic_num, noteCategoryBrand)
        }
        if (response.cpu_num) {
          getCategoryDetail(response.cpu_num, noteCategoryCpu)
        }
        if (response.ran_num) {
          getCategoryDetail(response.ran_num, noteCategoryMemory)
        }
        if (response.lcd_size_num) {
          getCategoryDetail(response.lcd_size_num, noteCategoryLcdSizeNum)
        }
        if (response.bonus_1_num) {
          getCategoryDetail(response.bonus_1_num, noteCategoryBonus1Num)
        }
        if (response.deduct_1_num) {
          getCategoryDetail(response.deduct_1_num, noteCategoryDeduct1Num)
        }
        if (response.deduct_2_num) {
          getCategoryDetail(response.deduct_2_num, noteCategoryDeduct2Num)
        }
        if (response.deduct_3_num) {
          getCategoryDetail(response.deduct_3_num, noteCategoryDeduct3Num)
        }
        if (response.grade_num) {
          getCategoryDetail(response.grade_num, noteCategoryGradeNum)
        }
        if (response.special_num) {
          getSpecialNum(response.special_num, noteSpecialNum)
        }
      })
    }

    const getCategoryDetail = async (num, target) => {
      const apiUrl = '/api/notebook/category-detail'
      await getData(apiUrl, num).then(response => target.value = response)
    }

    const getOrderNum = async () => {
      const apiUrl = `/api/notebook/order/ordernum/${route.params.num}`
      orderNum.value = await listData(apiUrl)
    }

    const getSpecialNum = async(num, target) => {
      const apiUrl = '/api/notebook/special'
      await getData(apiUrl,num).then(response => target.value = response)
    }

    const handleBack = async () => {
      location.href = "/checkup/result"
      // router.back()
    }

    const handleCanceledSale = async () => {
      await updateNoteOrderIsConfirm('C')
    }
    
    const handleApplySale = () => {
      if (checkValidate()) {
        postOrderPicture()
      }
    }

    const postOrderPicture = async () => {
      showLoading(true)
      // const apiUrl = '/api/notebook/order/picture'
      const apiUrl = '/api/order/picture'
      let formData = new FormData()
      const file = DataURIToBlob(order_picture)
      const fileOfBlob = new File([file], 'notebook_order_picture.jpg', {type:'image/jpeg'})
      formData.append('file', fileOfBlob)
      formData.append('ord_number', orderNum.value)
      await addFileData(apiUrl, formData).then(response => {
        if(response.result === 'success') {
          putOrderInfo()
        }
      })
      await updateNoteOrderIsConfirm('F')
      showLoading(false)
    }

    const putOrderInfo = async () => {
      const apiUrl = `/api/notebook/order/`
      const param = {
        num: Number(route.params.num),
        savina_shop_code: savina_shop_code.value,
        order_name: order_name.value,
        user_password: user_password.value,
        sn: sn.value,
        // order_picture: `https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/notebook/${orderNum.value}/notebook_order_picture.jpg`
        order_picture: `https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/hardware/${orderNum.value}/notebook_order_picture.jpg`
      }
      await updateData(apiUrl, param).then(() => {
        location.href = '/checkup/apply'
        // router.push({ path: '/checkup/apply' })
        /* router.push({
          name: 'checkup',
          params: {
            isState: 'apply'
          }
        }) */
      })
    } 

    const handleInput = (id, e) => {
      if (id === 'savina_shop_code') {
        savina_shop_code.value = e.target.value
      } else if (id === 'order_name') {
        order_name.value = e.target.value
      } else if (id === 'user_password') {
        user_password.value = e.target.value
      } else if (id === 'sn') {
        sn.value = e.target.value
      }
    }

    const handleOrderPicture = async () => {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      order_picture.value = image.dataUrl
    }

    const removeOrderPicture = async () => {
      order_picture.value = undefined
    }

    const checkValidate = () => {
      let message;
      if (!savina_shop_code.value) {
        message = t('messageSavinaShopCodeText')
        showMessage(message)
        return false
      } else if (!order_name.value) {
        message = t('messageOrderNameText')
        showMessage(message)
        return false
      } else if (!order_picture.value) {
        message = t('messageOrderPictureText')
        showMessage(message)
        return false
      } else {
        return true
      }
    }
    
    const showMessage = (message) => {
      toastController.create({
        message: message,
        duration: 3000,
        color: 'danger',
        cssClass: 'custom-toast',
        buttons: [
          {
            text: t('buttonLabelClose'),
            role: 'cancel'
          }
        ],
      }).then(toast => toast.present())
    }
    
    const showLoading = async (type) => {
      const loading = await loadingController.create({
        message: t('messageLoadingText'),
        // duration: 3000,
        cssClass: 'custom-loading'
      })
      if (type) {
        loading.present()
      } else {
        loading.dismiss()
      }
    }

    const handlemBarcodeModal = () => {
      isOpenBarcodeModal.value = true
    }

    const onDecode = (result) => {
      sn.value = result
      isOpenBarcodeModal.value = false
    }

    const onWillDismiss = () => {
      isOpenBarcodeModal.value = false
    };
    return {
      isOpenBarcodeModal,
      cameraOutline,
      trash,
      savina_shop_code,
      order_name,
      user_password,
      sn,
      order_picture,
      orderInfo,
      noteCategoryBrand,
      noteCategoryCpu,
      noteCategoryMemory,
      noteCategoryLcdSizeNum,
      noteCategoryBonus1Num,
      noteCategoryDeduct1Num,
      noteCategoryDeduct2Num,
      noteCategoryDeduct3Num,
      noteCategoryGradeNum,
      noteSpecialNum,
      qrcodeInfo,
      total_price,
      putOrderInfo,
      handleBack,
      handleCanceledSale,
      handleApplySale,
      handleInput,
      handleOrderPicture,
      removeOrderPicture,
      onDecode,
      onWillDismiss,
      handlemBarcodeModal
    }
  }
}
</script>

<style scoped>
.l-photo :deep ion-col {
  text-align: center;
  position: relative;
}
.l-photo :deep ion-button,
.l-photo .photo {
  width: 150px;
  height: 150px;
  --background: transparent;
  --background-focused: transparent;
  --background-hover: rgba(0, 0, 0, 0.3);
  border: 2px solid #2a7df6;
  border-radius: 5px;
  font-size: 1em;
  --color: #2a7df6;
}

.l-photo .photo {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.l-photo :deep ion-img {
  position: absolute;
  width: 145px;
  height: 145px;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.l-photo .clear {
  --color: rgb(209, 207, 207);
  font-size: 30px;
}

ion-modal#sn {
  --width: fit-content;
  --min-width: 400px;
  --max-width: 80%;
  --height: fit-content;
  --border-radius: 6px;
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

@media (max-width: 320px) {
  .l-photo {
    flex-direction: column;
  }
  .l-photo :deep ion-col,
  .l-photo .photo {
    width: 100% !important;
    max-width: 100% !important;
    flex: 1 !important;
  }
}
</style>
